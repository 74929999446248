const handleScroll = () => {
  if (window.scrollY <= 100) {
    // this.topTrue();
  } else {
    // this.topFalse();
  }

  // add menu trigger classes
  const body = document.querySelector('body')
  const trigger = document.querySelector('.banner')
  const menuHeight = document.querySelector('#menu').offsetHeight
  if (trigger) {
    const triggerBottom = trigger.offsetTop + trigger.offsetHeight
    if (window.scrollY > triggerBottom - menuHeight) {
      body.classList.add('menu-past-trigger')
    } else {
      body.classList.remove('menu-past-trigger')
    }
  }

  window.requestAnimationFrame(smartMenu)

  // // animate banners
  const bannerBg = document.querySelector('.banner-bg')
  if (bannerBg) {
    if (bannerBg && window.scrollY < bannerBg.offsetHeight) {
      window.requestAnimationFrame(animateBg)
    }
  }
}

let lastScrollY = null
const smartMenu = () => {
  const menu = document.querySelector('#menu')
  const delta = 5
  const thisScrollY = window.scrollY // new variable = distance from top of screen
  const scrollDistance = thisScrollY - lastScrollY
  if (Math.abs(scrollDistance) <= delta)
    // if the difference since last scrolling is less than 5,
    return // do nothing
  if (menu.classList.contains('stick')) return
  if (thisScrollY < 200) {
    menu.classList.remove('up')
    return
  }
  if (scrollDistance > 0) {
    // if scrolling down,
    menu.classList.add('up')
  } else {
    // if scrolling up,
    menu.classList.remove('up')
  }
  if (window.innerHeight + thisScrollY + 1 >= document.body.offsetHeight) {
    // hit bottom of page
    menu.classList.remove('up')
  }
  lastScrollY = thisScrollY
}

const animateBg = () => {
  let bannerBg = document.querySelector('.banner-bg')
  let bannerHeight = bannerBg.offsetHeight
  let setScroll = window.scrollY * -0.1
  let setOpacity = (bannerHeight - window.scrollY) / bannerHeight
  bannerBg.style.transform = `translate3d(0, ${setScroll}px, 0)`
  bannerBg.style.opacity = setOpacity

  let bannerText = document.querySelector('.banner .text-wrap')
  if (bannerText) {
    let setScrollText = window.scrollY * -0.2
    bannerText.style.transform = `translate3d(0, ${setScrollText}px, 0)`
    let setOpacity2 = (bannerHeight - window.scrollY) / bannerHeight
    bannerText.style.opacity = setOpacity2
  }

  let scrolldown = document.querySelector('.scroll-down button')
  if (scrolldown) {
    let setOpacity2 = (bannerHeight - window.scrollY * 2) / bannerHeight
    scrolldown.style.opacity = setOpacity2
  }
}

window.addEventListener('scroll', handleScroll)
// setTimeout(() => {
//   document.querySelector('.menu .loaded-wrap').classList.remove('up')
// }, 1000)
